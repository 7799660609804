<template>
  <div
    class="header flex sticky top-0 z-20 shadow-md"
    :style="{'background-color': '#f2f2f2'}"
  >
    <div
      class="text-3xl font-bold flex items-center px-8 cursor-pointer"
    >
      <h1 class="logo">
        <nuxt-link
          to="/"
          class="logo_img"
        >
          <img
            src="~/assets/img/new_logo.png"
            class="logo_img1"
          >
          <img
            src="~/assets/img/new_logo_text.png"
            class="logo_img2"
          >
        </nuxt-link>
      </h1>
    </div>
    <div class="flex-1">
      <Menu
        class="h-full justify-center"
        :menu-item-list="menuItemList"
        type="firstMenu"
      />
    </div>
    <div class="flex items-center mr-5">
      <div class="user_circle">
        <SvgIcon
          class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-[#FFBD16]"
          icon-class="user"
          size="28px"
        />
      </div>
      <div class="mx-2">
        <span>
          {{ userName }}
        </span>
      </div>
      <clientOnly>
        <el-badge
          :hidden="!showTotal"
          :value="showTotal"
          class="flex items-center"
        >
          <el-dropdown>
            <div class="pr-2">
              <SvgIcon
                icon-class="pull_down"
              />
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="onSetting">
                  设置
                </el-dropdown-item>
                <el-dropdown-item @click="taskStatus">
                  <el-badge
                    :hidden="!showTotal"
                    :value="showTotal"
                    is-dot
                  >
                    任务状态
                  </el-badge>
                </el-dropdown-item>
                <el-dropdown-item @click="logout">
                  退出
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-badge>
      </clientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ElMessageBox, ElMessage } from 'element-plus'

defineProps<{
  menuItemList: nuxtLinkMenuItem[]
}>()

const useTaskStatus = $stores.taskStatus.useTaskStatus()

// 退出登录 start
// const authingSdk = inject($inject.authingSdk)
const userStore = $stores.user.useUserStore()

const userName = computed(() => {
  const { teacherUserInfo } = userStore
  return teacherUserInfo ? teacherUserInfo.name : ''
})

function logout() {
  ElMessageBox.confirm('退出后返回登录界面', '确认退出？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    $utils.oauthLogout()
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消！'
    })
  })
}
// 退出登录 end

function onSetting() {
  navigateTo('/settings')
}

function taskStatus() {
  navigateTo('/taskStatus')
}

// 展示红点累计数据
const showTotal = computed(() => {
  const { newGenTotal } = useTaskStatus
  return newGenTotal
})
</script>

<style lang="scss" scoped>
.header {
  height: 68px !important;
  background: url('~/assets/img/head_left_img@2x.png') no-repeat,
              url('~/assets/img/head_right_img@2x.png') no-repeat right;
}
.title {
  font-size: 26px;
  font-weight: bold;
  color: theme('colors.assist1');
  line-height: 35px;
}

.user_circle {
  width: 44px;
  height: 44px;
  background: rgba(250,199,60,0.3);
  border-radius: 50%;
  position: relative;
}

.logo {
  // width: 140px;
  height: 100%;
}

.logo_img {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  // background-image: url('~/assets/img/new_logo.png');
  // background-repeat: no-repeat;
  // background-size: 140px 140px;
  // background-position: 0;
}

.logo_img1 {
  height: 60%;
}

.logo_img2 {
  height: 40%;
  margin-left: 10px;
}
</style>
