<template>
  <div
    class="min-h-screen flex flex-col"
  >
    <TheHeader :menu-item-list="menuItemList" />
    <slot />
    <TheFooter />
  </div>
</template>

<script lang="ts" setup>
const menuItemList = reactive<nuxtLinkMenuItem[]>([
  {
    label: '首页',
    path: '/home',
    icon: 'home'
  },
  {
    label: '教学管理',
    path: '/teachingManagement',
    icon: 'prepare_lessons'
  },
  {
    label: '备课组卷',
    path: '/prepareLessons',
    icon: 'tab_icon3'
  }
])
</script>
